import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import {
  Title,
  Button,
  Section,
  Box,
  Text,
  InputGroup,
} from "../../components/Core";

import { device } from "../../utils";

import imgHero from "../../assets/image/png/l7-hero-image.png";
import imgAppStore from "../../assets/image/png/app-store.png";

const ImgRight = styled(Box)`
  @media ${device.sm} {
  }
`;

const ButtonContainer = styled(Box)`
  margin-top: 35px;
  display: flex;
  justify-content: center;
`;

const ButtonApp = styled.a`
  padding: 0 9px;
  transition: 0.4s;
  &:visited {
    text-decoration: none;
  }
  &:hover {
    transform: translateY(-8px);
  }
`;

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <Section bg="#FCFDFE" className="position-relative" pb="60px !important">
        <div className="pt-5"></div>
        <Container>
          <Row className="align-items-center position-relative">
            <Col
              md="5"
              xl="4"
              className="position-static pb-5"
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-delay="500"
              data-aos-once="true"
            >
              <ImgRight>
                <img src={imgHero} alt="" className="img-fluid" />
              </ImgRight>
            </Col>
            <Col md="7" xl="6" className="offset-xl-1">
              <div
                data-aos="fade-left"
                data-aos-duration="750"
                data-aos-delay="500"
                data-aos-once="true"
              >
                <Box>
                  <Title>
                    Meet your new mobile Markdown editor.
                  </Title>
                  <Box mb={4}>
                    <Text color="dark">
                      Markcurial is a lightweight simple markdown editor with syntax highlighting, live previewing, theme customization and file management.
                    </Text>
                  </Box>
                  <ButtonContainer>
                    <ButtonApp href="https://apps.apple.com/us/app/markcurial-markdown-editor/id1594063100">
                      <img src={imgAppStore} alt="" className="img-fluid" />
                    </ButtonApp>
                  </ButtonContainer>
                </Box>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Hero;
