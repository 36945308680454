import React from "react";
import Hero from "../sections/landing6/Hero";

import PageWrapper from "../components/PageWrapper";

const LandingPage6 = () => {
  return (
    <>
      <PageWrapper>
        <Hero />
      </PageWrapper>
    </>
  );
};
export default LandingPage6;
